import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueMatomo from 'vue-matomo'
import de from '../locales/de.json'
import en from '../locales/en.json'
import nl from '../locales/nl.json'
import router from './router'
import App from './App.vue'

Vue.config.productionTip = false

Vue.use(VueI18n)

Vue.use(VueMatomo, {
    host: 'https://stats.pleio.nl',
    siteId: config.VUE_APP_ENV_MATOMO_SITE_ID,
    router: router,
    disableCookies: true,
})

const messages = {
    nl: nl,
    en: en,
    de: de,
}

const availableLanguages = Object.keys(messages)

const i18n = new VueI18n({
    locale: 'nl',
    fallbackLocale: 'en',
    messages,
})

new Vue({
    router,
    i18n,
    render: h => h(App),
    data: {
        availableLanguages: availableLanguages,
    },
}).$mount('#app')
